@font-face {
    font-display: block;
    font-family: 'homa_icons';
    font-style: normal;
    font-weight: normal;
    src: url('../fonts/homa_icons/homa_icons.eot');
    src: url('../fonts/homa_icons/homa_icons.eot#iefix') format('embedded-opentype'),
        url('../fonts/homa_icons/homa_icons.ttf') format('truetype'),
        url('../fonts/homa_icons/homa_icons.woff') format('woff'),
        url('../fonts/homa_icons/homa_icons.svg#homa_icons') format('svg');
}

[class^='icon-'],
[class*=' icon-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'homa_icons' !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 1;
    speak: none;
    text-transform: none;
}

.icon-bag-full::before {
    content: '\2282';
}

.icon-full-heart::before {
    content: '\a90';
}

.icon-checkmark::before {
    content: '\a901';
}

.icon-stores::before {
    content: '\a900';
}

.icon-correct::before {
    content: '\a30';
}

.icon-order-one::before {
    content: '\a58';
}

.icon-order-two::before {
    content: '\a31';
}

.icon-order-three::before {
    content: '\a32';
}

.icon-order-four::before {
    content: '\a33';
}

.icon-order-five::before {
    content: '\a34';
}

.icon-order-six::before {
    content: '\a35';
}

.icon-account::before {
    content: '\a41';
}

.icon-wishlist::before {
    content: '\a42';
}

.icon-shopping-bag::before {
    content: '\a43';
}

.icon-search::before {
    content: '\a44';
}

.icon-hamburguer-menu::before {
    content: '\a45';
}

.icon-facebook::before {
    content: '\a46';
}

.icon-instagram::before {
    content: '\a47';
}

.icon-pinterest::before {
    content: '\a48';
}

.icon-youtube::before {
    content: '\a49';
}

.icon-mbway::before {
    content: '\22ff';
}

.icon-linkedin::before {
    content: '\4a';
}

.icon-payshop::before {
    content: '\4b';
}

.icon-maestro::before {
    content: '\4c';
}

.icon-mastercard::before {
    content: '\4d';
}

.icon-visa::before {
    content: '\a4e';
}

.icon-mb::before {
    content: '\a4f';
}

.icon-arrow-right::before {
    content: '\a50';
}

.icon-minus::before {
    content: '\a51';
}

.icon-plus::before {
    content: '\a52';
}

.icon-close::before {
    content: '\a53';
}

.icon-filters::before {
    content: '\a54';
}

.icon-delivery::before {
    content: '\a55';
}

.icon-payment::before {
    content: '\a56';
}

.icon-trash::before {
    content: '\a57';
}

.icon-location-map::before {
    content: '\a59';
}

.icon-warning::before {
    content: '\a5a';
}

.icon-paypal::before {
    content: '\e900';
}
